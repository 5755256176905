import { wrapCreateBrowserRouter } from '@sentry/react';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { ReefContextProvider } from '../contexts';
import {
  AuthErrorOutlet,
  AuthRequiredOutlet,
  ReefAuthNavigatorOutlet,
  ReefLeftNavOutlet,
  RootOutlet,
  SplashPageOutlet,
} from './Outlets';
import { ActiveClientRequiredOutlet } from './Outlets/ActiveClientRequiredOutlet';
import { ServiceWorker } from './ServiceWorker';

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#react-router-v6
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const App = () => (
  <ReefContextProvider>
    <ServiceWorker />
    <RouterProvider
      router={sentryCreateBrowserRouter(
        createRoutesFromElements(
          <Route element={<RootOutlet />}>
            <Route element={<ReefAuthNavigatorOutlet />}>
              <Route element={<SplashPageOutlet />}>
                <Route element={<AuthErrorOutlet />}>
                  <Route path="login" lazy={() => import('./Routes/lazy/Login')} />
                  <Route path="sign-up">
                    <Route index lazy={() => import('./Routes/lazy/SignUp')} />
                    <Route path="challenge" lazy={() => import('./Routes/lazy/Challenge')} />
                    <Route
                      path="resend-code"
                      lazy={() => import('./Routes/lazy/ResendSecurityCode')}
                    />
                  </Route>
                  <Route path="skeleton">
                    <Route index lazy={() => import('./Routes/lazy/Skeleton')} />
                    <Route path="knock" lazy={() => import('./Routes/lazy/Knock')} />
                  </Route>
                </Route>
              </Route>
              <Route element={<AuthRequiredOutlet />}>
                <Route element={<ActiveClientRequiredOutlet />}>
                  <Route element={<ReefLeftNavOutlet />}>
                    <Route path="filter-customers">
                      <Route index lazy={() => import('./Routes/lazy/AllCustomers')} />
                      <Route
                        path=":filterId"
                        lazy={() => import('./Routes/lazy/FilterCustomers')}
                      />
                    </Route>
                    <Route path="sprint">
                      <Route index lazy={() => import('./Routes/lazy/SprintDashboard')} />
                      <Route path=":sprintId" lazy={() => import('./Routes/lazy/SprintDetails')} />
                      <Route
                        path=":sprintId/delete"
                        lazy={() => import('./Routes/lazy/DeleteSprint')}
                      />
                      <Route path="create" lazy={() => import('./Routes/lazy/CreateSprint')} />
                      <Route path="capture" lazy={() => import('./Routes/lazy/CaptureSprint')} />
                    </Route>
                    <Route path="customer-details">
                      <Route path=":accountId" lazy={() => import('./Routes/lazy/CustomerDetails')}>
                        <Route index lazy={() => import('./Routes/lazy/CustomerDetailsIndex')} />
                        <Route
                          path="products"
                          lazy={() => import('./Routes/lazy/ProductMetrics')}
                        />
                        <Route
                          path="contracts"
                          lazy={() => import('./Routes/lazy/CustomerContracts')}
                        />
                        <Route
                          path="pipeline"
                          lazy={() => import('./Routes/lazy/CustomerPipeline')}
                        />
                        <Route
                          path="support-cases"
                          lazy={() => import('./Routes/lazy/CustomerSupportCases')}
                        />
                        <Route
                          path="marketing"
                          lazy={() => import('./Routes/lazy/CustomerMarketing')}
                        />
                        <Route
                          path="sprints"
                          lazy={() => import('./Routes/lazy/CustomerSprints')}
                        />
                        {/* Legacy redirects */}
                        <Route
                          path="customer-360"
                          element={<Navigate relative="path" to=".." replace />}
                        />
                        <Route
                          path="custom-reports"
                          element={<Navigate relative="path" to=".." replace />}
                        />
                      </Route>
                    </Route>
                    <Route path="manage" lazy={() => import('./Routes/lazy/Manage')}>
                      <Route index lazy={() => import('./Routes/lazy/ManageIndex')} />
                      <Route path="profile" lazy={() => import('./Routes/lazy/Profile')} />
                      <Route path="playbooks" lazy={() => import('./Routes/lazy/Playbooks')} />
                      <Route path="users" lazy={() => import('./Routes/lazy/Users')} />
                    </Route>
                    <Route path="work">
                      <Route index lazy={() => import('./Routes/lazy/MyWorkIndex')} />
                      <Route path="my" lazy={() => import('./Routes/lazy/UserWork')}>
                        <Route index lazy={() => import('./Routes/lazy/MyWorkIndex')} />
                        <Route path="sprints" lazy={() => import('./Routes/lazy/MySprints')} />
                        <Route path="actions" lazy={() => import('./Routes/lazy/MyActions')} />
                        <Route
                          path="recommendations"
                          lazy={() => import('./Routes/lazy/MyRecommendationsOutlet')}
                        >
                          <Route
                            path=":recId/dismiss"
                            lazy={() => import('./Routes/lazy/DismissMyRecommendation')}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="data">
                      <Route index element={<Navigate relative="route" to="quality" replace />} />
                      <Route path="quality" lazy={() => import('./Routes/lazy/DataSyncQuality')} />
                    </Route>
                    <Route path="analytics">
                      <Route index element={<Navigate relative="route" to="renewals" replace />} />
                      <Route path="renewals" lazy={() => import('./Routes/lazy/Renewals')} />
                      <Route path="risk" lazy={() => import('./Routes/lazy/RiskRecommendations')} />
                      <Route
                        path="growth"
                        lazy={() => import('./Routes/lazy/GrowthRecommendations')}
                      />
                    </Route>
                    <Route path="*" lazy={() => import('./Routes/lazy/NotFound')} />
                  </Route>
                </Route>
                <Route path="connectors">
                  <Route index lazy={() => import('./Routes/lazy/DataConnectors')} />
                  <Route
                    path=":sourceType/create"
                    lazy={() => import('./Routes/lazy/CreateTokenCache')}
                  />
                </Route>
                <Route path="*" lazy={() => import('./Routes/lazy/NotFound')} />
              </Route>
              <Route
                path="/"
                element={<Navigate relative="route" to="filter-customers" replace />}
              />
              <Route
                path="/list-of-customers/*"
                element={<Navigate to="/filter-customers" replace />}
              />
            </Route>
          </Route>,
        ),
      )}
    />
  </ReefContextProvider>
);
